<template>
  <List
    title="memberships"
    resource="memberships"
    :basePath="basePath"
    :fields="fields"
    ref="list"
    on-row-click="edit"
    data-provider="$raaDataProvider"
    :initial-filter="entityDefaultFilter"
    :filter-classes="{container: 'w-2/3 items-end mb-6', quickFilters: 'w-1/2 mr-6', searchContainer: 'flex-1'}"
    :pageSize="20"
    infinity-scroll
  >
    <template v-slot:quickfilter="{applyFilter}">
      <div class="form-col">
        <label>{{entityFilterName}}</label>
        <Dropdown
          :options="entityFilterOptions"
          :value="entityFilterValue"
          @input="v => { applyFilter(mapEntityFilterToQuery(v)); }"
          show-all
          no-clear
        />
      </div>
    </template>
    <template v-slot:inline-actions="actionsScope">
      <button
        class="btn-action-warning"
        v-if="!actionsScope.record.deletedAt"
        @click.stop="deleteMembership(actionsScope.record)"
      >
        <icon class="w-5 h-5 mr-1" name="trash"/>
      </button>
    </template>
  </List>
</template>

<script>
  import List from "@/components/auth/list/List";
  import Icon from "@/components/ui/Icon";
  import ModalNavigation from "@/mixins/ModalNavigation";
  import ConfirmationMixin from "@/mixins/ConfirmationMixin";
  import NotifyMixin from "@/mixins/NotifyMixin";
  import DateTimeField from "@/components/auth/list/fields/DateTimeField";
  import Dropdown from "@/components/ui/Dropdown";
  import EntityFilterMixin from "@/components/auth/EntityFilterMixin";

  export default {
    name: "MembershipsList",
    components: {
      List,
      Icon,
      Dropdown,
    },
    mixins: [ModalNavigation, ConfirmationMixin, EntityFilterMixin, NotifyMixin],
    computed: {
      basePath: function () {
        return this.$route.path;
      },
    },
    data() {
      return {
        fields: [
          {
            name: 'partyName',
            title: 'party',
            sortField: 'partyName',
          },
          {
            name: 'communityName',
            title: 'community',
            sortField: 'communityName',
          },
          {
            name: DateTimeField,
            sortField: 'engagementDate',
            title: 'engagement date',
            switch: {
              source: 'engagementDate'
            },
            titleClass: 'text-center',
            dataClass: 'text-center',
          },
          {
            name: DateTimeField,
            sortField: 'disEngagementDate',
            title: 'disengagement date',
            switch: {
              source: 'disEngagementDate'
            },
            titleClass: 'text-center',
            dataClass: 'text-center',
          },
          {
            name: 'inline-actions',
            title: '',
            width: "8%",
          }
        ]
      }
    },
    methods: {
      deleteMembership(membership) {
        this.requestConfirmation({
          confirmationMessage: 'Are you sure you want to delete this membership?',
          confirmBtnText: 'yes',
          cancelBtnText: 'no',
          confirmationType: 'warning'
        })
          .then(confirmed => confirmed
            ? this.$raaDataProvider.delete('memberships', {id: membership.id})
              .then(() => {
                this.$refs.list.doSearch();
                this.notifySuccess('membership deleted');
              })
              .catch(error => this.notifyError(error.message))
            : null
          );
      }
    }
  }
</script>
